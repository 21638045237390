import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toNumber } from 'utils/toNumber';

type Param<T> = T | undefined;
type StringParam = Param<string>;

function useSearchParamsEx(
    param: string
): [StringParam, (_: StringParam) => void] {
    const [params, setParams] = useSearchParams();

    function setParam(value: StringParam) {
        if (value == null || value.trim().length === 0) params.delete(param);
        else params.set(param, value);
        setParams(params);
    }

    const s = params.get(param);
    const value = s == null || s.trim().length === 0 ? undefined : s;
    return [value, setParam];
}

export function useParam<T>(
    param: string,
    defaultValue: T | undefined,
    converter: (value: StringParam) => T | undefined,
    reverseConverter: (value: T | undefined) => StringParam
): [T | undefined, (value: T | undefined) => void] {
    const [value, _setParam] = useSearchParamsEx(param);
    const setParam = (v: T | undefined) => _setParam(reverseConverter(v));
    return [converter(value), setParam];
}

export function useStringParam(
    param: string,
    defaultValue?: StringParam
): [StringParam, (_: StringParam) => void] {
    const [value, setParam] = useSearchParamsEx(param);
    return [value ?? defaultValue, setParam];
}

export function useNumberParam(
    key: string,
    defaultValue?: number
): [number | undefined, (_: number | undefined) => void] {
    const [value, setValue] = useParam<number>(
        key,
        defaultValue,
        x => toNumber(x) ?? defaultValue,
        x => (x == null ? undefined : `${x}`)
    );
    return [value, setValue];
}

export function useBooleanParam(
    key: string,
    defaultValue: boolean
): [boolean, () => void] {
    const [_value, setValue] = useParam<boolean>(
        key,
        defaultValue,
        x => (x === '1' ? true : false),
        x => (x === true ? '1' : undefined)
    );

    const value = _value ?? defaultValue;
    const toggleValue = () => setValue(!value);

    return [value, toggleValue];
}

export function usePageLocation(): [string, (_: string) => void] {
    const { pathname: location } = useLocation();
    const navigate = useNavigate();
    const setLocation = (newLocation: string) => navigate(newLocation);
    return [location, setLocation];
}

export function useShowHelp(): boolean {
    const { pathname } = useLocation();
    const showHelp = pathname.endsWith('help');
    return showHelp;
}

export function usePageParam() {
    const [_page, setPage] = useNumberParam('page');
    const goToFirstPage = () => setPage(undefined);
    const goToPreviousPage = () => setPage(page > 2 ? page - 1 : undefined);
    const goToNextPage = () => setPage(page <= 1 ? 2 : page + 1);

    const page = _page ?? 1;

    return { page, goToFirstPage, goToPreviousPage, goToNextPage };
}
