import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AppState {
    title: string;
    helpLocation?: string;
}

const initialState: AppState = {
    title: 'Shankasur',
    helpLocation: '/about',
};

export const appSlice = createSlice({
    name: 'app',
    initialState,

    reducers: {
        setTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload;
        },
        setHelpLocation: (state, action: PayloadAction<string | undefined>) => {
            state.helpLocation = action.payload;
        },
    },
});

export default appSlice.reducer;
